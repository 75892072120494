@use "../abstracts/" as *;

header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 50%;
    margin: 0;
    position: fixed;
    top: 0;
    translate: -50%;
    width: 100%;
    z-index: 999;
    padding: 20px 16%;
    background: linear-gradient(354deg, rgb(255 255 255 / 50%), rgb(239 229 246 / 34%));
    backdrop-filter: blur(2px);

    .header-logo {
        width: rem(126);
        object-fit: contain;
    }

    nav {
        display: flex;
        align-items: center;
        column-gap: rem(31);
    }

    .right {
        display: flex;
        align-items: center;
        column-gap: rem(72);

        .ctas {
            display: flex;
            align-items: center;
            column-gap: rem(16);
        }
    }

    &.theme-white {

        .header-logo {
            filter: grayscale(1) brightness(100);
        }

        a {
            color: #fff;
        }

        .button {
            border-color: #fff;
            .background-effect {
                background-color: #fff;
            }

            .btn-content {
                color: #fff;
            }

            &:hover {
                .btn-content {
                    color: #000;
                }
            }
        }

        .account {
            filter: invert(1) brightness(100);
        }
    }
}