@use "../abstracts/"as *;

#home {
	section {
		min-height: 100vh;

		&.hero {
			//transform: translate(0) !important;

			.sofa {
				.canap-illu {
					position: absolute;
					top: 50%;
					left: 50%;
					translate: -50% -50%;

					img {
						position: relative;
						z-index: 2;
					}

					&::after {
						position: absolute;
						top: 0;
						display: block;
						content: '';
						background: linear-gradient(45deg, #f0fe9d, #ccccff, #bbedff, #ffcef8);
						background-size: 400%;
						animation: gradientAnim 20s linear infinite;
						height: 100%;
						width: 100%;
						z-index: 0;
						filter: blur(40px);
						border-radius: 100vmax;
						scale: 1;
					}
				}

				.pill {
					border: rem(8) solid #f7f6fb;
					display: inline-block;
					padding: rem(18) rem(50);
					border-radius: 100vmax;
					box-sizing: content-box;
					font-weight: 700;
					font-family: "neulis-neue", sans-serif;
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 2;
					transform-origin: center;
					translate: -50% -50%;
					scale: 1;

					animation: scaleUp 0.8s backwards ease;

					&.emote {
						aspect-ratio: 1;
						padding: rem(8);
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.content {
				position: absolute;
				bottom: rem(50);
				left: 50%;
				translate: -50% -50%;
				width: rem(656);
				text-align: center;

				.headline {
					font-size: rem(30);
					font-weight: 700;
				}

				.button {
					text-align: center;
					margin: rem(29) auto 0;
				}
			}
		}

		&.exploration {
			background-color: #F7F6FB;
			padding-top: 162px;
			padding-bottom: 40px;
			min-height: auto;
			position: relative;

			&:before {
				content: '';
				width: 100%;
				height: 162px;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				background-image: url(../../../public/images/elipse.svg);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top center;
			}

			.container {
				width: 1400px;
				margin: auto;
				padding: 150px 0;
			}

			* {
				color: #fff;
			}

			.little-headline {
				font-size: 18px;
				font-weight: 300;
				text-align: center;
				margin-bottom: 48px;
				font-family: 'Neulis Alt';
				&.w-600 {
					font-weight: 600;
				}
			}

			.top-content {
				margin-bottom: 150px;
	
				.logos-partners {
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					justify-content: center;
					column-gap: 77px;
					img {
						max-height: 90px;
						width: auto;
					}
				}
			}

			.bottom-content {
				.big-headline {
					font-size: 70px;
					font-weight: 600;
					text-align: center;
					font-family: 'Neulis Alt';
					margin-bottom: 56px;

					.green-gradient {
						background: linear-gradient(to right, #94fd9e 0%, #e9fc7c 99%);
						-webkit-background-clip: text;
  						-webkit-text-fill-color: transparent;
					}
					.blue-gradient {
						background: linear-gradient(to right, #98e1fc 0%, #bcebff 29%, #c9d2ff 71%, #b0b0fc 100%);
						-webkit-background-clip: text;
  						-webkit-text-fill-color: transparent;
					}
				}

				.connector-slider {
					width: 850px;
					margin: auto;
					position: relative;
					overflow: hidden;
					// &::before, &::after {
					// 	content: '';
					// 	width: 262px;
					// 	height: 93px;
					// 	display: block;
					// 	position: absolute;
					// 	top: 50%;
					// 	left: 0;
					// 	--background-image: url(../../../public/images/Fade-out.svg);
					// 	background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #0b0b0b 60%, #000 100%);
					// 	transform: translateY(-50%) rotate(180deg);
					// 	z-index: 100;
					// }

					// &::after {
					// 	transform: translateY(-50%);
					// 	left: auto;
					// 	right: 0;
					// }

					.overlay-slider-connector {
						width: 100%;
						height: 200vh;
						position: absolute;
						display: block;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						box-shadow: inset 0 0 60px 235px #F7F6FB;
						z-index: 100;
						pointer-events: none;
					}

					.item-slider {
						display: inline-flex;
						width: auto;
						flex-flow: row wrap;
						align-items: center;
						justify-content: flex-start;
						padding: 11px 21px;
						border: solid 2px #fff;
						border-radius: 500px;
						column-gap: 15px;
						font-size: 18px;
						font-weight: 600;

						img {
							width: 28px;
							height: 28px;
						}
					}
				}
			}
		}

		&.canapp {
			background-image: url(../../../public/images/bg-canapp.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			padding: 82px 0;

			.container {
				width: 1580px;
				margin: auto;
			}

			h2 {
				font-size: 55px;
  				font-weight: bold;
				margin-bottom: 20px;
			}

			.subtitle {
				font-size: 18px;
  				font-weight: 600;
			}

			h2, .subtitle {
				font-family: 'Neulis Alt';
				text-align: center;
				width: 100%;
				display: inline-block;
			}

			.img-content {
				display: flex;
				flex-flow: row wrap;
				align-items: stretch;
				justify-content: space-between;
				margin-top: 170px;
				& > div {
					width: 50%;
					display: flex;
					flex-flow: column;
					align-items: center;
					justify-content: center;
					text-align: center;
					
					h3 {
						font-size: 70px;
						font-weight: bold;
						font-family: 'Neulis Alt';
						margin-bottom: 15px;
					}

					h4 {
						font-size: 30px;
						font-weight: bold;
						font-family: 'Neulis Alt';
						margin-bottom: 22px;
					}

					.button {
						background-color: #fff;
					}
				}
			}
		}
	}
}