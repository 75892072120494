@use '../abstracts/' as *;

.button {
    padding: rem(12) rem(30);
    border: 2px solid #000;
    border-radius: 100vmax;
    width: max-content;
    overflow: hidden;
    position: relative;
    font-family: 'neulis-sans', sans-serif;
    z-index: 1;
    background: transparent;

    .btn-content {
        color: #000;
        position: relative;
        z-index: 2;
        transition: all 0.4s ease;
    }

    .background-effect {
        display: inline-block;
        border-radius: 100vmax;
        aspect-ratio: 1;
        width: 8px;
        background-color: #000;
        transition: scale 0.6s ease;
        position: absolute;
        z-index: 1;
        scale: 0;
    }

    &:hover {
        cursor: pointer;
        .btn-content {
            color: #fff;
        }

        .background-effect {
            scale: 75;
        }
    }
}