@keyframes scaleUp {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

@keyframes gradientAnim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 300% 0;
  }
  100% {
    background-position: 0 0;
  }
}