@use "../abstracts/" as *;

/* ---- Mobile ---- */
h2,
h3 {
	font-size: 1.5rem;
	line-height: 75%;
	font-style: normal;
	font-weight: 900;

	@include desktop {
		font-size: 2.25rem;
		line-height: 99%;
	}
}

h3, h4, h5, h6 {
	text-transform: unset;
	font-size: 1rem;
	font-weight: 400;
}

h4 {
	font-size: .75rem;
}

p {
	font-style: normal;
	font-weight: 400;
	font-size: 0.625rem;
	line-height: 160%;

	@include desktop {
		font-size: 0.875rem;
		line-height: 140%;
	}
}

.navLink {
	font-size: 1.5rem;
	font-weight: 900;
	user-select: none;
}

/* ---- Tablet ---- */
@include tablet {
	h2 {
		font-size: 2.5rem;
	}

	p {
		font-size: 0.875rem;
	}

	.navLink {
		font-size: 2.5rem;
	}
}