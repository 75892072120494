@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-Bold.eot');
    src: local('Neulis Alt Bold'), local('NeulisAlt-Bold'),
        url('fonts-files/NeulisAlt-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-Bold.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-Bold.woff') format('woff'),
        url('fonts-files/NeulisAlt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt Extra';
    src: url('fonts-files/NeulisAlt-ExtraBold.eot');
    src: local('Neulis Alt Extra Bold'), local('NeulisAlt-ExtraBold'),
        url('fonts-files/NeulisAlt-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-ExtraBold.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-ExtraBold.woff') format('woff'),
        url('fonts-files/NeulisAlt-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-Black.eot');
    src: local('Neulis Alt Black'), local('NeulisAlt-Black'),
        url('fonts-files/NeulisAlt-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-Black.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-Black.woff') format('woff'),
        url('fonts-files/NeulisAlt-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-BoldItalic.eot');
    src: local('Neulis Alt Bold Italic'), local('NeulisAlt-BoldItalic'),
        url('fonts-files/NeulisAlt-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-BoldItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-BoldItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt Extra';
    src: url('fonts-files/NeulisAlt-ExtraBoldItalic.eot');
    src: local('Neulis Alt Extra Bold Italic'), local('NeulisAlt-ExtraBoldItalic'),
        url('fonts-files/NeulisAlt-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-ExtraBoldItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-ExtraBoldItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-BlackItalic.eot');
    src: local('Neulis Alt Black Italic'), local('NeulisAlt-BlackItalic'),
        url('fonts-files/NeulisAlt-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-BlackItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-BlackItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-Regular.eot');
    src: local('Neulis Alt'), local('NeulisAlt-Regular'),
        url('fonts-files/NeulisAlt-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-Regular.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-Regular.woff') format('woff'),
        url('fonts-files/NeulisAlt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-Italic.eot');
    src: local('Neulis Alt Italic'), local('NeulisAlt-Italic'),
        url('fonts-files/NeulisAlt-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-Italic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-Italic.woff') format('woff'),
        url('fonts-files/NeulisAlt-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt Semi';
    src: url('fonts-files/NeulisAlt-SemiBold.eot');
    src: local('Neulis Alt Semi Bold'), local('NeulisAlt-SemiBold'),
        url('fonts-files/NeulisAlt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-SemiBold.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-SemiBold.woff') format('woff'),
        url('fonts-files/NeulisAlt-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-LightItalic.eot');
    src: local('Neulis Alt Light Italic'), local('NeulisAlt-LightItalic'),
        url('fonts-files/NeulisAlt-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-LightItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-LightItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-Thin.eot');
    src: local('Neulis Alt Thin'), local('NeulisAlt-Thin'),
        url('fonts-files/NeulisAlt-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-Thin.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-Thin.woff') format('woff'),
        url('fonts-files/NeulisAlt-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt Semi';
    src: url('fonts-files/NeulisAlt-SemiBoldItalic.eot');
    src: local('Neulis Alt Semi Bold Italic'), local('NeulisAlt-SemiBoldItalic'),
        url('fonts-files/NeulisAlt-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-SemiBoldItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-SemiBoldItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-ThinItalic.eot');
    src: local('Neulis Alt Thin Italic'), local('NeulisAlt-ThinItalic'),
        url('fonts-files/NeulisAlt-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-ThinItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-ThinItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-MediumItalic.eot');
    src: local('Neulis Alt Medium Italic'), local('NeulisAlt-MediumItalic'),
        url('fonts-files/NeulisAlt-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-MediumItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-MediumItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt Extra';
    src: url('fonts-files/NeulisAlt-ExtraLightItalic.eot');
    src: local('Neulis Alt Extra Light Italic'), local('NeulisAlt-ExtraLightItalic'),
        url('fonts-files/NeulisAlt-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-ExtraLightItalic.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-ExtraLightItalic.woff') format('woff'),
        url('fonts-files/NeulisAlt-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-Medium.eot');
    src: local('Neulis Alt Medium'), local('NeulisAlt-Medium'),
        url('fonts-files/NeulisAlt-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-Medium.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-Medium.woff') format('woff'),
        url('fonts-files/NeulisAlt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt';
    src: url('fonts-files/NeulisAlt-Light.eot');
    src: local('Neulis Alt Light'), local('NeulisAlt-Light'),
        url('fonts-files/NeulisAlt-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-Light.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-Light.woff') format('woff'),
        url('fonts-files/NeulisAlt-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neulis Alt Extra';
    src: url('fonts-files/NeulisAlt-ExtraLight.eot');
    src: local('Neulis Alt Extra Light'), local('NeulisAlt-ExtraLight'),
        url('fonts-files/NeulisAlt-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('fonts-files/NeulisAlt-ExtraLight.woff2') format('woff2'),
        url('fonts-files/NeulisAlt-ExtraLight.woff') format('woff'),
        url('fonts-files/NeulisAlt-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}