/* ---- BREAKPOINTS ---- */
/* Everything below tablet is phone */
$bp-tablet: 768px;
$bp-tablet-xl: 1024px;
$bp-desktop: 1366px;
$bp-desktop-xl: 1440px;

/* Tablet media query */
@mixin tablet {
	@media (min-width: #{$bp-tablet}) and (max-width: #{$bp-desktop - 1}) {
		@content;
	}
}

/*  Desktop media query */
@mixin desktop {
	@media (min-width: #{$bp-desktop}) {
		@content;
	}
}

/* Large desktop media query */
@mixin desktop-xl {
	@media (min-width: #{$bp-desktop-xl}) {
		@content;
	}
}